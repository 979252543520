/* Homepage Styling rgb(242, 242, 242) */ 
/* Ensure the body and html can scroll */
html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Allow pages to extend beyond the viewport and scroll vertically */
body {
    display: block; /* Ensure the body is treated as a block container */
    overflow-y: auto; /* Enable vertical scrolling */
}


/* This */

body {
    font-family: Arial, sans-serif;
    background-color: #111;
    color: white;
}

/* Hero Section */
.hero-section {
    position: relative;
    width: 100%;
    height: 75vh;
    background-color: #222;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.hero-image-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
}

.hero-content {
    position: absolute;
    text-align: center;
    color: #fff;
}

.hero-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: gold;
    margin-bottom: 0.5rem;
}

.hero-subtitle {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.hero-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: gold;
    color: black;
    font-weight: bold;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.hero-link:hover {
    background-color: #d4af37;
}

/* Posts Section */
.posts-section {
    padding: 2rem;
}

.section-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-align: center;
    color: gold;
}

.posts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.post-card {
    background-color: #222;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.post-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.post-card-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

.post-card-content {
    padding: 1rem;
}

.post-card-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: gold;
    margin-bottom: 0.5rem;
}

.post-card-subtitle {
    font-size: 0.9rem;
    color: #ccc;
}

.no-posts {
    text-align: center;
    color: #ccc;
    font-size: 1.2rem;
}

