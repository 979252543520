.configurations {
    margin: 20px auto;
    max-width: 1200px;
    padding: 20px;
    background-color: #111;
    color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.configurations h2 {
    margin-bottom: 20px;
}

table thead {
    background-color: #333;
}

table tbody tr:nth-child(even) {
    background-color: #222;
}
