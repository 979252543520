/* UserProfile.css */
body {
    font-family: Arial, sans-serif;
    background-color: #111;
    color: white;
}

.container {
    max-width: 65%;
    margin: 0 auto;
    padding: 20px;
}

/* Flex layout for desktop */
.fighter-info-container {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 20px;
}

.fighter-info {
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: #222;
    padding: 20px;
    border-radius: 8px;
    flex: 1;
}

.fighter-image {
    width: 350px;
    height: 350px;
    object-fit: cover;
    border-radius: 8px;
    position: relative;
}

.profile-pic-container {
    position: relative;
    display: inline-block;
}

.edit-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    display: none; /* Hidden by default */
    z-index: 2;
}

.profile-pic-container:hover .edit-icon {
    display: block;
}

.upload-section {
    margin-top: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.fighter-details {
    display: flex;
    flex-direction: column;
}

.fighter-details p {
    font-size: 1.1em;
}

.fighter-details h1 {
    font-size: 2em;
}

.record-box {
    display: flex;
    gap: 10px;
    font-size: 1.8em;
    margin-top: 10px;
}

.win, .loss, .draw {
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
}

.win {
    background-color: green;
}

.loss {
    background-color: red;
}

.draw {
    background-color: blue;
}

.profile-section {
    background-color: #222;
    padding: 20px;
    border-radius: 8px;
}

.match-history {
    margin-top: 20px;
}

.current-championships ul {
    list-style: none;
    padding: 0;
}

.title-image {
    width: 50px;
    height: auto;
    margin-right: 10px;
    vertical-align: middle;
}

.table {
    width: 100%;
    color: #fff;
    border-collapse: collapse;
    background-color: #222;
}

.table th, .table td {
    padding: 10px;
    border: 1px solid #444;
}

.table th {
    text-align: left;
    background-color: #333;
}

.table-striped tbody tr:nth-child(odd) {
    background-color: #2b2b2b;
}

.season-tabs {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.season-tab {
    padding: 5px 15px;
    border-radius: 5px;
    background-color: #333;
    color: white;
    border: none;
    cursor: pointer;
}

.season-tab.active {
    background-color: #555;
    font-weight: bold;
}

/* Additional CSS for the new features */
.opponent-link {
    color: black;
    text-decoration: none;
}

.opponent-link:hover {
    color: #ddd;
}

.title-icon {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    vertical-align: middle;
}

/* Modal styling for uploading profile picture */
.modal-content {
    background-color: #222;
    color: white;
}

.modal-header, .modal-body, .modal-footer {
    border-color: #444;
}

.modal-title {
    color: #fff;
}

.btn-primary {
    background-color: #ffcc00;
    border: none;
}

.btn-primary:hover {
    background-color: #e6b800;
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
    .container {
        max-width: 100%;
        padding: 10px;
    }

    .fighter-info-container {
        flex-direction: column;
    }

    .fighter-info, .profile-section {
        width: 100%;
        padding: 15px;
    }

    .fighter-image {
        width: 100%;
        height: auto;
        max-width: 250px;
        margin: 0 auto;
    }

    .fighter-details h1 {
        font-size: 1.5em;
        text-align: center;
    }

    .fighter-details p {
        font-size: 1em;
        text-align: center;
    }

    .record-box {
        font-size: 1.5em;
        justify-content: center;
        margin-top: 15px;
    }

    .current-championships, .match-history, .season-records {
        width: 100%;
        padding: 15px;
        margin-top: 15px;
    }

    .season-tabs {
        flex-direction: column;
    }

    .table th, .table td {
        padding: 8px;
        font-size: 0.9em;
    }

    .title-image {
        width: 40px;
        height: auto;
    }
}
