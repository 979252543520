body {
    font-family: Arial, sans-serif;
    color: white;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.6);
}

.background-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.tale-of-tape-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    position: relative;
}

.results-pending {
    text-align: center;
    font-size: 3em;
    color: red;
    font-weight: bold;
    margin-top: -50px;
}

.tale-of-tape {
    display: flex;
    position: relative;
    left:10%;
    width: 100%;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
}

.player-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    position: relative;
    background: rgba(0, 0, 0, 0.9);
    padding-bottom: 50px;
}

.profile-image {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    margin: 0 auto;
    border: 3px solid gold;
    position: relative;
    z-index: 2;
    top: -100px; /* Slightly overlap the fighter image */
    background: rgba(0, 0, 0, 0.8);
}

.fighter-image {
    width: 300px;
    height: 400px;
    border: 3px solid gold;
    border-radius: 8px;
    object-fit: cover;
    margin-top: -30px; /* Align closer to the profile picture */
    z-index: 1;
}

.center-stats-container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center stats vertically */
    align-items: center;
    width: 40%;
    height: auto;
    padding: 30px;
    padding-bottom:150px;
    background: rgba(0, 0, 0, 0.95);
    border-radius: 10px;
}

.title-section h1 {
    font-size: 3em;
    color: gold;
    margin: 0;
    font-weight: bold;
}

.title-section h2 {
    font-size: 1.5em;
    color: white;
    margin: 5px 0;
}

.center-stats-title {
    font-size: 2.5em;
    font-weight: bold;
    color: black;
    margin: 20px 0;
    margin-bottom: 50px;
    padding: 10px;
    background: gold;
    width: 100%;
    text-align: center;
    border-radius: 5px;
}

.center-stats-content {
    width: 100%;
}

.stat-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    font-size: 1.5em;
}

.stat-label {
    width: 40%;
    text-align: center;
    font-weight: bold;
}

.stat-value-left,
.stat-value-right {
    width: 30%;
    text-align: center;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.action-button {
    background-color: gold;
    color: black;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 1.5em;
    cursor: pointer;
    margin: 0 10px;
}

.action-button:hover {
    background-color: darkgoldenrod;
}

.admin-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: red;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    cursor: pointer;
}

.admin-button:hover {
    background-color: darkred;
}

.title-display {
    text-align: center;
    margin-bottom: 20px;
}

.title-image {
    width: 150px;
    height: auto;
    margin-bottom: 10px;
}


/* Mobile Styles */
@media (max-width: 768px) {
    .tale-of-tape {
        flex-direction: column;
        align-items: center;
        padding: 10px;
        top:-50px;
        position: relative;
        left: 0;
    }

    .player-side {
        display: none;
    }

    .center-stats-container {
        width: 100%;
        margin: 20px 0;
        padding: 15px;
    }

    .button-container {
        flex-direction: column;
        width: 100%;
        padding: 0 10px;
    }

    .action-button {
        width: 100%;
        margin: 5px 0;
        font-size: 1em;
    }

    .popup-content {
        width: 90%;
    }

    .toggle-container .toggle-option {
        flex: 1 1 45%; /* Two toggles per row on mobile */
        margin: 5px;
    }

    .button-container {
        display: flex;
        justify-content: center;
        margin-top: -75px;
    }

    .center-stats-title {
        font-size: 1.5em;
        
    }
    
    
    .stat-row {
        font-size: .8em;
    }
}
