/* src/components/Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #111;
    color: white;
}

.header-left {
    display: flex;
    align-items: center;
}

.logo {
    height: 50px;
    margin-right: 20px;
}

.nav-link {
    margin-right: 15px;
    color: white !important; /* Important to override Bootstrap styles */
    text-decoration: none;
    font-weight: bold;
}

.nav-link:hover {
    color: #ffcc00 !important; /* Override Bootstrap styles */
}

.header-right {
    position: relative;
}

.user-dropdown {
    cursor: pointer;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
}

.dropdown-menu {
    background-color: #333;
    border: none;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-top: 8px;
    padding: 0;
    min-width: 150px;
}

.dropdown-toggle::after {
    color: #fff;
}

.dropdown-item {
    padding: 10px 15px;
    color: white;
    text-decoration: none;
    font-size: 14px;
}

.dropdown-item:hover {
    background-color: #444;
    color: #fff;
}

.dropdown-content {
    display: none;
}

.modal-content {
    background-color: #222;
    color: #fff;
    border: none;
}

.modal-header,
.modal-footer {
    border: none;
}

.modal-body input {
    background-color: #333;
    color: #fff;
    border: 1px solid #555;
}

.modal-body input::placeholder {
    color: #bbb;
}
