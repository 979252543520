/* PostDetail.css */

.post-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #111;
    color: #fff;
    font-family: Arial, sans-serif;
}

.post-detail .post-header {
    width: 100%;
    max-width: 800px;
    text-align: center;
    margin-bottom: 1.5rem;
}

.post-detail .post-header h1 {
    font-size: 2.5rem;
    color: gold;
    margin-bottom: 0.5rem;
}

.post-detail .post-header h2 {
    font-size: 1.5rem;
    font-weight: normal;
    color: #ccc;
    margin-bottom: 1rem;
}

.post-detail .post-image {
    width: 100%;
    max-width: 800px;
    height: 100%;
    object-fit: cover; /* Ensures the image fills the container without distortion */
    border-radius: 10px;
    margin-bottom: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.post-detail .post-content {
    width: 100%;
    max-width: 800px;
    line-height: 1.8;
    font-size: 1.2rem;
    color: #ddd;
    text-align: left;
}

.post-detail .post-content p {
    margin-bottom: 1rem;
}

.post-detail .back-button {
    margin-top: 2rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    background-color: gold;
    color: #111;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.post-detail .back-button:hover {
    background-color: #e6b800;
}
